import { render, staticRenderFns } from "./SideNavBar.vue?vue&type=template&id=7634dc37&scoped=true"
import script from "./SideNavBar.vue?vue&type=script&lang=js"
export * from "./SideNavBar.vue?vue&type=script&lang=js"
import style0 from "./SideNavBar.vue?vue&type=style&index=0&id=7634dc37&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7634dc37",
  null
  
)

export default component.exports