import axios from 'axios'
import store from '@/store'
import {getToken, getRefreshToken, setToken, removeToken, removeRefreshToken} from '@/utils/cookieUtil'
import {Notification} from 'element-ui';
import Vue from 'vue'

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 36000 // request timeout
})

const cleanSession = () => {
    removeToken()
    removeRefreshToken()
    sessionStorage.removeItem("user")
    store.state.userInfo = null
    store.state.loginFlag = true
}

const refreshToken = () => {
    const refreshData = {
        refresh: getRefreshToken()
    }
    return service.post('/token/refresh/', refreshData).then(response => {
        if (response.code === "000000") {
            setToken(response.data.access)
            return response.data.access
        } else {
            cleanSession()
        }
    })
}

// request interceptor
service.interceptors.request.use(
    config => {
        // if (config.url != '/oauth/wechat/is_login' && config.url != '/v1/im/chat' && config.url != '/v1/article/search' && config.url != '/v1/tag/') {
        //     window.vm.$bus.$emit('showLoading');
        // }
        //do something before request is sent
        let token = getToken()
        if (token != null) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            config.headers.Authorization = `Token ${token}`
        }

        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        // window.vm.$bus.$emit('hideLoading');
        const res = response.data
        // store.commit('SET_LOADING',false);
        // if the custom code is not 20000, it is judged as an error.

        if (res.code !== '000000') {
            if (res.code === '4011') {
                let token = getToken()
                // 如果有token会尝试刷新token
                if (token) {
                    // 检测到授权失败，则会尝试使用refresh token重新获取一次token，如果获取失败则判定为登录失效
                    return refreshToken().then((newToken) => {
                        // 刷新成功，重新发送原始请求
                        const originalRequest = response.config;
                        originalRequest.headers.Authorization = `Token ${newToken}`;
                        return service(originalRequest);
                    }).catch((error) => {
                        cleanSession()
                        return Promise.reject(response);
                    });
                } else {
                    cleanSession()
                    return Promise.reject(response);
                }
            }
            // 如果是校验微信登录是否授权的接口 则不进行错误输出
            // if (response.config.url !== "/oauth/wechat/is_login") {
            //     window.vm.$toast.error(res.message);
            // }

            return Promise.reject(response)
        } else {
            return res
        }
    },
    error => {
        return Promise.reject(error)
    }
)

export default service