import request from '@/utils/request'

export function fetchArticleList(params) {
    return request({
        url: '/blog/article/',
        method: 'get',
        params: params
    })
}

export function getArticleByUserId(params) {
    return request({
        url: '/blog/article/',
        method: 'get',
        params: params
    })
}

export function deleteMyArticle(id) {
    return request({
        url: '/blog/article/',
        method: 'delete',
        params: {
            id: id
        }
    })
}

export function getMyArticleInfo(id) {
    return request({
        url: '/v1/article/selectMyArticleInfo',
        method: 'get',
        params: {
            id: id
        }
    })
}

export function readMarkdownFile(data) {
    return request({
        url: '/v1/article/readMarkdownFile',
        method: 'post',
        data
    })
}

export function insertArticle(data) {
    return request({
        url: '/blog/article/',
        method: 'post',
        data
    })
}

export function updateArticle(data) {
    return request({
        url: '/blog/article/',
        method: 'put',
        data
    })
}

export function searchArticle(params) {
    return request({
        url: '/blog/article/',
        method: 'get',
        params: params
    })
}

export function articleInfo(id) {
    return request({
        url: `/blog/article/${id}/`,
        method: 'get',
    })
}

export function checkCode(code, articleId) {
    return request({
        url: '/blog/check_code/',
        method: 'get',
        params: {
            code: code,
            article_id: articleId
        }
    })
}

export function articleLike(id) {
    return request({
        url: '/blog/like/',
        method: 'get',
        params: {
            id: id
        }
    })
}

export function archive() {
    return request({
        url: '/blog/archive/',
        method: 'get',
    })
}

export function fetchTagList() {
    return request({
        url: '/blog/tag/',
        method: 'get',
        params: {
            page_size: -1
        }
    })
}

export function featchHomeData() {
    return request({
        url: '/blog/home/',
        method: 'get',
    })
}

export function getHot() {
    return request({
        url: '/blog/hot/',
        method: 'get'
    })
}

export function report() {
    return request({
        url: '/blog/report/',
        method: 'get',
    })
}

export function getWebSiteInfo() {
    return request({
        url: '/blog/web_site_info/',
        method: 'get',
    })
}

export function emailLogin(data) {
    return request({
        url: '/oauth/emailLogin',
        method: 'post',
        data
    })
}

export function unLogin(data) {
    return request({
        url: '/login',
        method: 'post',
        data
    })
}

export function forgetPassword(data) {
    return request({
        url: '/change_pwd/',
        method: 'post',
        data
    })
}

export function logout(data) {
    return request({
        url: '/logout/',
        method: 'post',
        data
    })
}

export function openAuthUrl(source) {
    return request({
        url: '/oauth/render/' + source,
        method: 'get'
    })
}

export function wxIsLogin(scene) {
    return request({
        url: '/wx/xcx/qrcode-status',
        method: 'get',
        params: {
            scene: scene
        }
    })
}

export function getLoginByScene(scene) {
    return request({
        url: '/get_login_by_scene/',
        method: 'get',
        params: {
            scene: scene
        }
    })
}

export function getWechatLoginCode() {
    return request({
        url: '/wx/xcx/qrcode',
        method: 'get',

    })
}

export function sendEmailCode(data) {
    return request({
        url: '/email_code/',
        method: 'post',
        data
    })
}

export function emailRegister(data) {
    return request({
        url: '/register/',
        method: 'post',
        data
    })
}

export function updateUserInfo(data) {
    return request({
        url: '/update/',
        method: 'post',
        data
    })
}

export function getUserInfo() {
    return request({
        url: '/blog/userinfo/',
        method: 'get'
    })
}

export function getUserinfo() {
    return request({
        url: '/blog/userinfo/',
        method: 'get'
    })
}


export function upload(data, params) {
    return request({
        url: '/upload/',
        method: 'POST',
        headers: {'Content-Type': 'multipart/articles-data'},
        data,
        params: params
    })
}

export function featchCategory() {
    return request({
        url: '/blog/category/',
        method: 'get',
        params: {
            page_size: -1
        }
    })
}

export function addFeedback(data) {
    return request({
        url: '/blog/feedback/',
        method: 'post',
        data
    })
}

export function getCollect() {
    return request({
        url: '/v1/collect/',
        method: 'get'
    })
}

export function collect(id) {
    return request({
        url: '/blog/collect/',
        method: 'get',
        params: {
            articleId: id
        }
    })
}

export function cancelCollect(id) {
    return request({
        url: '/v1/collect/',
        method: 'delete',
        params: {
            articleId: id
        }
    })
}

export function followedUser(userId) {
    return request({
        url: '/v1/followed/insertFollowed',
        method: 'post',
        params: {
            userId: userId
        }
    })
}

export function deleteFollowedUser(userId) {
    return request({
        url: '/v1/followed/deleteFollowed',
        method: 'delete',
        params: {
            userId: userId
        }
    })
}

export function getVerifyCode(username) {
    return request({
        url: '/code/',
        method: 'get',
        params: {
            username: username
        }
    })
}