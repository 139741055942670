<template>
    <div>
        <el-dialog :lock-scroll="false" :close-on-click-modal="false" class="dialog" center :title="title"
                   :visible.sync="dialogFormVisible">
            <!-- 账号登录 -->
            <div v-show="index === 1">
                <el-form :model="unLoginForm" :rules="unLoginRule" ref="unLoginForm">
                    <el-form-item label="账号" :label-width="formLabelWidth" prop="username">
                        <el-input prefix-icon="el-icon-user" placeholder="请输入账号" @keyup.enter.native="login"
                                  v-model="unLoginForm.username"
                                  autocomplete="off" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="密码" :label-width="formLabelWidth" prop="password">
                        <el-input prefix-icon="el-icon-lock" placeholder="请输入密码" @keyup.enter.native="login"
                                  v-model="unLoginForm.password"
                                  autocomplete="off" clearable
                                  show-password></el-input>
                    </el-form-item>
                    
                    <el-form-item label="验证码" :label-width="formLabelWidth" prop="verify_code">
                        <div class="el-row" span="24">
                            <div class="el-col el-col-16">
                                <el-input
                                        type="text"
                                        maxlength="4"
                                        placeholder="请输入验证码"
                                        prefix-icon="el-icon-position"
                                        v-model="unLoginForm.verify_code"
                                        clearable
                                        autocomplete="off"
                                ></el-input>
                            </div>
                            <div class="el-col el-col-8">
                                <div class="login-code" @click="getImgCode">
                                    <span class="login-code-img" v-if="!codeImg">点击获取</span>
                                    <el-image class="login-code-img" :src="codeImg" v-else></el-image>
                                </div>
                            </div>
                        </div>
                    </el-form-item>
                </el-form>
                <el-button type="success" class="loginBtn" @click="login" round>登录</el-button>
                
                <div class="regitstBtn">
                    <a class="regist hand-style" @click="handleChangeLoginMethod(2)">账号注册</a>
                    <a class="forget hand-style" @click="handleChangeLoginMethod(3)">忘记密码</a>
                </div>
                
                <div>
                    <div class="social-login-title">社交账号登录</div>
                    <div class="social-login-wrapper">
                        <!--                        <a class="hand-style" v-show="isShow(2)" @click="openAuthLogin('qq')">-->
                        <!--                            <svg-icon icon-class="qq" />-->
                        <!--                        </a>-->
                        
                        <!--                        <a class="hand-style" v-show="isShow(4)" @click="openAuthLogin('gitee')">-->
                        <!--                            <svg-icon icon-class="gitee" />-->
                        <!--                        </a>-->
                        <!--                        <a class="hand-style" v-show="isShow(3)" @click="openAuthLogin('weibo')">-->
                        <!--                            <svg-icon icon-class="weibo" />-->
                        <!--                        </a>-->
                        <a class="hand-style" v-show="isShow(5)" @click="handleChangeLoginMethod(4)">
                            <svg-icon icon-class="wechat"/>
                        </a>
                        <!--                        <a class="hand-style" v-show="isShow(6)" @click="openAuthLogin('github')">-->
                        <!--                            <svg-icon icon-class="github" />-->
                        <!--                        </a>-->
                    </div>
                </div>
            </div>
            <!-- 注册 -->
            <div v-show="index === 2">
                <el-form :model="registerForm" :rules="registerFormRule" ref="registerForm" label-position="left">
                    <el-form-item label="邮箱" :label-width="formLabelWidth" prop="email">
                        <el-input class="input" placeholder="请输入邮箱" v-model="registerForm.email"
                                  autocomplete="off" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="昵称" :label-width="formLabelWidth" prop="nickname">
                        <el-input class="input" placeholder="请输入昵称" v-model="registerForm.nickname"
                                  autocomplete="off" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="密码" :label-width="formLabelWidth" prop="password">
                        <el-input class="input" placeholder="请输入密码" v-model="registerForm.password"
                                  autocomplete="off" show-password clearable></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" :label-width="formLabelWidth" prop="password1">
                        <el-input class="input" placeholder="请再次输入密码" v-model="registerForm.password1"
                                  autocomplete="off" show-password clearable></el-input>
                    </el-form-item>
                    <el-form-item label="验证码" :label-width="formLabelWidth" prop="email_code">
                        <div style="display: flex;">
                            <el-input class="input" placeholder="请输入验证码" v-model="registerForm.email_code"
                                      autocomplete="off" clearable></el-input>
                            <a v-if="showSendBtnFlag" class="send hand-style" @click="handleSendEmailCode">发送</a>
                            <a v-else class="send hand-style">{{ countdown }}s</a>
                        </div>
                    </el-form-item>
                </el-form>
                
                <el-button type="danger" class="loginBtn hand-style" @click="register" round>注册</el-button>
                
                <div class="goLoginBtn">
                    已有账号，<a @click="handleChangeLoginMethod(1)" class="hand-style">去登录</a>
                </div>
            </div>
            <!-- 修改密码 -->
            <div v-show="index === 3">
                <el-form :model="changePwdForm" :rules="changePwdFormRule" ref="changePwdForm" label-position="left">
                    <el-form-item label="邮箱" :label-width="formLabelWidth" prop="email">
                        <el-input class="input" placeholder="请输入邮箱" v-model="changePwdForm.email"
                                  autocomplete="off" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" :label-width="formLabelWidth" prop="password">
                        <el-input class="input" placeholder="请输入密码" v-model="changePwdForm.password"
                                  autocomplete="off"
                                  show-password clearable></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" :label-width="formLabelWidth" prop="password1">
                        <el-input class="input" placeholder="请再次输入密码" v-model="changePwdForm.password1"
                                  autocomplete="off"
                                  show-password clearable></el-input>
                    </el-form-item>
                    <el-form-item label="验证码" :label-width="formLabelWidth" prop="email_code">
                        <div style="display: flex;">
                            <el-input class="input" placeholder="请输入邮箱验证码" v-model="changePwdForm.email_code"
                                      autocomplete="off" clearable></el-input>
                            <a v-if="showSendBtnFlag" class="send hand-style"
                               @click="handleChangePwdSendEmailCode">发送</a>
                            <a v-else class="send">{{ countdown }}s</a>
                        </div>
                    </el-form-item>
                </el-form>
                
                <el-button type="primary" class="loginBtn hand-style" @click="forgetPassword" round>修改</el-button>
                
                <div class="goLoginBtn">
                    已有账号，<a @click="handleChangeLoginMethod(1)" class="hand-style">去登录</a>
                </div>
            </div>
            
            <div v-show="index === 4">
                <div v-if="wechatLoginCode">
                    
                    
                    <el-image class="wxImg" :src="wechatLoginCode.qrcode" v-if="wechatQrcodeStatus === 0"></el-image>
                    
                    <div class="image-container" v-else-if="wechatQrcodeStatus === 1">
                        <el-image class="wxImg img-blur" :src="wechatLoginCode.qrcode"></el-image>
                        <div class="overlay-text">已扫码</div>
                    </div>
                    
                    <div class="image-container" v-else>
                        <el-image class="wxImg img-blur" :src="wechatLoginCode.qrcode"></el-image>
                        <div class="overlay-text">已登录</div>
                    </div>
                
                
                </div>
                <div v-else>
                    <el-image class="wxImg">
                        <div slot="error" class="image-slot">
                            加载中<span class="dot">...</span>
                        </div>
                    </el-image>
                </div>
                
                <div style="text-align: center;margin-top: 20px;" slot="footer" class="dialog-footer">
                    <el-button @click="handleChangeLoginMethod(1)">返回登录</el-button>
                </div>
            </div>
        </el-dialog>
    
    </div>
</template>

<script>
import {
    emailLogin,
    wxIsLogin,
    openAuthUrl,
    getWechatLoginCode,
    sendEmailCode,
    emailRegister,
    forgetPassword,
    getLoginByScene,
    getVerifyCode,
    unLogin,
} from "@/api";
import {setUrl, setToken, setRefreshToken} from '@/utils/cookieUtil'

export default {
    data: function () {
        return {
            unLoginForm: {
                login_type: 1,
                username: null,
                password: null,
                verify_code: null
            },
            registerForm: {
                email: null,
                nickname: null,
                password: null,
                password1: null,
                email_code: null
            },
            changePwdForm: {
                email: null,
                password: null,
                password1: null,
                email_code: null
            },
            form: {
                email: null,
                password: null,
            },
            code: null,
            timer: null,
            index: 1,
            wechatLoginFlag: false,
            title: "账号密码登录",
            showSendBtnFlag: true,
            formLabelWidth: '80px',
            wechatLoginCode: null,
            countdown: 60, // 倒计时初始值为 60 秒
            
            unLoginRule: {
                username: [
                    {required: true, message: '请输入用户名', trigger: 'blur'},
                    {min: 5, max: 150, message: '长度在 5 到 150 个字符', trigger: 'blur'}
                ],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                    {min: 5, max: 50, message: '长度在 5 到 50 个字符', trigger: 'blur'}
                ],
                verify_code: [
                    {required: true, message: '请输入图片验证码', trigger: 'blur'},
                    {min: 4, max: 4, message: '长度为 4 个字符', trigger: 'blur'}
                ],
            },
            registerFormRule: {
                email: [
                    {required: true, message: '请输入邮箱', trigger: 'blur'},
                ],
                nickname: [
                    {required: true, message: '请输入昵称', trigger: 'blur'},
                    {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'}
                ],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                    {min: 5, max: 50, message: '长度在 5 到 50 个字符', trigger: 'blur'}
                ],
                password1: [
                    {required: true, message: '请再次输入密码', trigger: 'blur'},
                    {validator: this.validatePassword1, trigger: 'blur'}
                ],
                email_code: [
                    {required: true, message: '请输入邮箱验证码', trigger: 'blur'},
                    {min: 6, max: 6, message: '长度为 6 个字符', trigger: 'blur'}
                ],
            },
            changePwdFormRule: {
                email: [
                    {required: true, message: '请输入邮箱', trigger: 'blur'},
                ],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                    {min: 5, max: 50, message: '长度在 5 到 50 个字符', trigger: 'blur'}
                ],
                password1: [
                    {required: true, message: '请再次输入密码', trigger: 'blur'},
                    {validator: this.validatePassword1, trigger: 'blur'}
                ],
                email_code: [
                    {required: true, message: '请输入邮箱验证码', trigger: 'blur'},
                    {min: 6, max: 6, message: '长度为 6 个字符', trigger: 'blur'}
                ],
            },
            codeImg: '',
            
            rules: {
                email: [
                    {required: true, message: '请输入账号', trigger: 'blur'},
                ],
                nickname: [
                    {required: true, message: '请输入昵称', trigger: 'blur'},
                ],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                ],
                code: [
                    {required: true, message: '请输入验证码', trigger: 'blur'},
                ]
            },
            
            wechatQrcodeStatus: 0,
        };
    },
    watch: {
        index: function (n, v) {
            if (n === 1) {
                this.$nextTick(() => {
                    this.$refs.unLoginForm.clearValidate()
                })
            }
            if (n === 2) {
                this.$nextTick(() => {
                    this.$refs.registerForm.clearValidate()
                })
            }
            if (n === 3) {
                this.$nextTick(() => {
                    this.$refs.changePwdForm.clearValidate()
                })
            }
        },
    },
    computed: {
        dialogFormVisible: {
            set(value) {
                clearInterval(this.timer);
                this.$store.state.loginFlag = value;
            },
            get() {
                this.index = 1
                return this.$store.state.loginFlag;
            }
        },
    },
    methods: {
        handleChangeLoginMethod(condition) {
            this.form = {}
            if (condition === 1) {
                clearInterval(this.timer);
                this.title = "账号密码登录"
            } else if (condition === 2) {
                this.title = "邮箱注册"
            } else if (condition === 3) {
                this.title = "忘记密码"
            } else {
                this.getWecahtLoginCode()
                this.title = "微信扫码登录"
            }
            this.index = condition;
            
        },
        forgetPassword() {
            this.$refs.changePwdForm.validate((valid) => {
                if (!valid) return
                forgetPassword(this.changePwdForm).then(res => {
                    this.$toast.success('修改成功');
                    this.$store.state.loginFlag = true;
                    this.forgetFlag = false
                    this.index = 1
                })
            });
        },
        register() {
            this.$refs.registerForm.validate((valid) => {
                if (!valid) return
                emailRegister(this.registerForm).then(res => {
                    this.$toast.success('注册成功');
                    this.$store.state.loginFlag = true;
                    this.emailRegistFlag = false
                    this.index = 1
                })
            });
        },
        handleSendEmailCode() {
            if (this.registerForm.email === null || this.registerForm.email === '') {
                this.$toast.error('请输入邮箱');
                return
            }
            const data = {
                email: this.registerForm.email
            }
            sendEmailCode(data).then(res => {
                this.timer = setInterval(() => {
                    if (this.countdown > 0) {
                        this.showSendBtnFlag = false
                        this.countdown--;
                    } else {
                        this.showSendBtnFlag = true
                        clearInterval(this.timer);
                        this.timer = null;
                        this.countdown = 60
                    }
                }, 1000);
                
                this.$toast.success('验证码发送成功');
            })
        },
        handleChangePwdSendEmailCode() {
            if (this.changePwdForm.email === null || this.changePwdForm.email === '') {
                this.$toast.error('请输入邮箱');
                return
            }
            const data = {
                email: this.changePwdForm.email
            }
            sendEmailCode(data).then(res => {
                this.timer = setInterval(() => {
                    if (this.countdown > 0) {
                        this.showSendBtnFlag = false
                        this.countdown--;
                    } else {
                        this.showSendBtnFlag = true
                        clearInterval(this.timer);
                        this.timer = null;
                        this.countdown = 60
                    }
                }, 1000);
                
                this.$toast.success('验证码发送成功');
            })
        },
        handleRegister(type) {
            this.form = {}
            this.$store.state.loginFlag = false;
            if (type === 1) {
                this.emailRegistFlag = true
            } else {
                this.forgetFlag = true
            }
        },
        close() {
            clearInterval(this.timer);
            this.$store.state.loginFlag = false;
            this.wechatLoginFlag = false
        },
        getWecahtLoginCode() {
            getWechatLoginCode().then(res => {
                this.wechatQrcodeStatus = 0
                this.wechatLoginCode = res.data
                this.$toast.success('验证码获取成功');
                this.timer = setInterval(() => {
                    // 轮询判断用户是否已经登录
                    wxIsLogin(res.data.scene).then(res => {
                        this.wechatQrcodeStatus = res.data.status
                        if (res.data.status === 2) {
                            clearInterval(this.timer)
                            this.getLoginByScene()
                        }
                    })
                }, 1000);
            })
        },
        
        // 如果微信扫码成功，根据scene获取登录信息
        getLoginByScene() {
            getLoginByScene(this.wechatLoginCode.scene).then(res => {
                setToken(res.data.access)
                setRefreshToken(res.data.refresh)
                this.$store.commit("setUserInfo", res.data)
                this.close()
                this.$toast.success('登录成功');
                window.location.reload()
            })
        },
        
        isShow(type) {
            return this.$store.state.webSiteInfo.loginTypeList.indexOf(type) != -1
        },
        //Enter事件
        handkeyEnter(event) {
            if (event.keyCode === 13) {
                this.login()
            }
        },
        login() {
            this.$refs['unLoginForm'].validate((valid) => {
                if (!valid) return
                //发送登录请求
                unLogin(this.unLoginForm).then(res => {
                    setToken(res.data.access)
                    setRefreshToken(res.data.refresh)
                    this.$store.commit("setUserInfo", res.data)
                    this.close()
                    this.$toast.success('登录成功');
                    window.location.reload()
                }).catch(err => {
                    this.$toast.success('账号、密码或验证码错误');
                })
            });
            
        },
        openAuthLogin(source) {
            //保留当前路径
            this.settingUrl()
            openAuthUrl(source).then(res => {
                window.open(res.data, "_self");
            });
        },
        settingUrl() {
            if (this.$route.path === "/articleInfo") {
                setUrl("articleId=" + this.$route.query.articleId)
            } else {
                setUrl(this.$route.path)
            }
        },
        // 获取图片验证码
        getImgCode() {
            this.$refs.unLoginForm.validateField('username', error => {
                if (!error) {
                    getVerifyCode(this.unLoginForm.username).then(res => {
                        this.codeImg = res.data
                    })
                }
            })
        },
        // 注册 确认密码
        validatePassword1(rule, value, callback) {
            if (this.index === 2) {
                if (value !== this.registerForm.password) {
                    callback(new Error('两次输入的密码不一致'));
                } else {
                    callback();
                }
            } else if (this.index === 3) {
                if (value !== this.changePwdForm.password) {
                    callback(new Error('两次输入的密码不一致'));
                } else {
                    callback();
                }
            } else {
                callback();
            }
            
        },
    }
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__wrapper {
    background-image: url(https://img.shiyit.com/20231007_1696659441438.jpg) !important;
}

.hand-style {
    cursor: pointer;
}

.dialog {
    
    @media screen and (min-width: 1119px) {
        /deep/ .el-dialog {
            min-width: 530px;
            width: 25%;
            border-radius: 10px;
        }
    }
    
    
    .wxImg {
        width: 50%;
        display: block;
        margin: 0 auto;
    }
    
    .img-blur {
        filter: blur(4px);
    }
    
    .image-container {
        position: relative;
    }
    
    .overlay-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(255, 255, 255, 0.8); /* 设置文本背景颜色和透明度 */
        padding: 8px 16px;
        border-radius: 4px;
        font-size: 16px;
    }
    
    
    .loginBtn {
        margin-top: 30px;
        text-align: center;
        display: block;
        margin: 0 auto;
        width: 50%;
    }
    
    .regitstBtn {
        
        margin: 22px 0;
        display: flex;
        justify-content: flex-end;
        
        .regist {
            margin-right: 22px;
        }
        
        .regist, .forget {
            cursor: pointer;
            
            &:hover {
                color: var(--theme-color);
            }
        }
        
        .forget {
            float: right;
        }
    }
    
    /deep/ .input input {
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
    }
    
    .send {
        width: 18%;
        color: var(--text-color);
        font-size: 14px;
        border: none;
        background-color: var(--background-color);
        
        &:hover {
            color: var(--theme-color)
        }
    }
    
    .goLoginBtn {
        margin-top: 20px;
        
        a {
            color: red;
        }
    }
    
    .social-login-title {
        margin-top: 1.5rem;
        color: #b5b5b5;
        font-size: 0.75rem;
        text-align: center;
        
        &::before {
            content: "";
            display: inline-block;
            background-color: #d8d8d8;
            width: 60px;
            height: 1px;
            margin: 0 12px;
            vertical-align: middle;
        }
        
        &::after {
            content: "";
            display: inline-block;
            background-color: #d8d8d8;
            width: 60px;
            height: 1px;
            margin: 0 12px;
            vertical-align: middle;
        }
    }
    
    .social-login-wrapper {
        margin-top: 1rem;
        font-size: 2rem;
        text-align: center;
        
        a {
            text-decoration: none;
            
            svg {
                width: 30px;
                height: 30px;
            }
        }
    }
}

.login-code {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 0 0 10px;
    user-select: none;
    
    .login-code-img {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2px;
        width: 100px;
        height: 38px;
        border: 1px solid var(--prev-border-color-base);
        color: var(--prev-color-text-primary);
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 5px;
        line-height: 38px;
        text-indent: 5px;
        text-align: center;
        cursor: pointer;
        transition: all ease 0.2s;
        border-radius: 4px;
        
        &:hover {
            border-color: var(--prev-border-color-hover);
            transition: all ease 0.2s;
        }
    }
}
</style>
