import Cookies from 'js-cookie'

export function setToken(value) {
    return Cookies.set("token", value);
}

export function setRefreshToken(value) {
    return Cookies.set("refresh", value);
}

export function getToken() {
    return Cookies.get("token");
}

export function removeToken() {
    return Cookies.remove("token");
}

export function getRefreshToken() {
    return Cookies.get("refresh");
}

export function removeRefreshToken() {
    return Cookies.remove("refresh");
}

export function setUrl(value) {
    return window.sessionStorage.setItem("baseUrl", value)
}

export function getUrl() {
    return window.sessionStorage.getItem("baseUrl")
}

export function setNotice(value) {
    return Cookies.set("notice", value, {expires: 90});
}

export function getNotice() {
    return Cookies.get("notice");
}